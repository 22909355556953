@if (basketItems?.length > 0) {
<div class="child-upgrade-checkout">
  <div class="left-panel">
    <div class="child-upgrade-payment-basket">
      <h5>{{ 'child.upgrade.checkout.title' | translate }}</h5>
      <h5 class="summary-title">{{ 'child.upgrade.checkout.item.summary.title' | translate }}</h5>

      <div class="child-upgrade-item-list">
        <div class="child-upgrade-basket-item" *ngFor="let item of basketItems">
          <img alt="" class="membership-image" [src]="item.Product.PrimaryImage | contentImage : '4'" />
          <div class="basket-item">
            <span class="child-upgrade-basket-item-desc font-size-14"
              ><span class="basket-label fw-bold">{{ item.Child.FirstName + ' ' + item.Child.LastName }}</span>
              <span class="basket-item">&nbsp;- {{ item.Product.DisplayName }}</span></span
            >
            <span class="child-upgrade-basket-item-cost"
              ><span class="basket-label font-size-12 fw-bold">{{ 'child.upgrade.checkout.price.label' | translate }}</span>
              <span class="price ms-2 font-size-12">{{ item.Product.MerchantPrice / 100 | FortressCurrencyPipe }}</span></span
            >
          </div>
        </div>
      </div>
      <a [routerLink]="'/child/upgrade'" class="text-danger mt-3 fw-bold d-none d-lg-flex font-size-14">{{
        'child.upgrade.checkout.cancel' | translate
      }}</a>
    </div>
  </div>
  <div class="right-panel mt-lg-0 mt-4">
    <div [ngClass]="{ 'd-none': editBillingAddress || editShippingAddress }" class="payment-summary">
      <!-- Shipping Address Card -->
      <ng-container
        *ngTemplateOutlet="
          addressTemplate;
          context: {
            $implicit: shippingAddressForm?.value,
            title: 'fgbcl.registration.payment.shipping.address.title' | translate : { fgbdefault: 'Shipping Address' },
            type: 'shipping'
          }
        "
      >
      </ng-container>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="shippingAddressCheckbox"
          [(ngModel)]="isSameAddress"
          (change)="toggleShipping()"
        />
        <label class="form-check-label" for="shippingAddressCheckbox">
          {{ 'registration.payment.shipping.checkbox.label' | translate }}
        </label>
      </div>

      <!-- Billing Address Card -->

      <ng-container *ngIf="!isSameAddress">
        <ng-container
          *ngTemplateOutlet="
            addressTemplate;
            context: {
              $implicit: billingAddressForm?.value,
              title: 'fgbcl.registration.payment.billing.address.title' | translate : { fgbdefault: 'Billing Address' },
              type: 'billing'
            }
          "
        >
        </ng-container>
      </ng-container>
      <!-- Gift Code -->
      <div class="gift-code-input">
        <div class="input-group rounded">
          <div class="form-floating m-0">
            <input type="text" class="form-control gift-code-input" [(ngModel)]="giftCodeInput" />
            <label class="text-info">{{ 'giftcode.input.label' | translate }}</label>
          </div>
          <div class="input-group-append">
            <button
              class="btn gift-code-btn btn-primary text-white"
              type="button"
              (click)="applyGiftCode()"
              [disabled]="disableApplyGiftCodesButton || !giftCodeInput"
            >
              {{ 'giftcode.input.button' | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- Summary -->
      <div class="card summary-card">
        <h5 class="summary-card-title">
          {{ 'kids.membership.upgrade.summary.label' | translate }}
        </h5>
        <div class="summary-item">
          <div class="summary-label">{{ 'child.upgrade.checkout.items.label' | translate : { fgbdefault: 'Items' } }}</div>
          <div class="summary-value">{{ totalAmount / 100 | FortressCurrencyPipe }}</div>
        </div>
        <div class="summary-item" *ngIf="totalShipping">
          <div class="summary-label">{{ 'child.upgrade.checkout.shipping.label' | translate : { fgbdefault: 'Shipping' } }}</div>
          <div class="summary-value">{{ totalShipping / 100 | FortressCurrencyPipe }}</div>
        </div>
        <ng-container *ngIf="totalTax > 0">
          <div class="summary-item">
            <div class="summary-label">{{ 'child.upgrade.checkout.tax.label' | translate : { fgbdefault: 'Tax' } }}</div>
            <div class="summary-value">{{ totalTax / 100 | FortressCurrencyPipe }}</div>
          </div>
        </ng-container>
        <div class="row mb-2" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
          <div class="col-12 font-size-12 fw-bold">Discount Code(s):</div>
          <ng-container *ngFor="let giftCode of appliedGiftCodes">
            <div class="col-9 font-size-14 d-flex align-items-center">
              <button class="gift-code-remove-btn" (click)="removeGiftCode(giftCode.Code)">
                <i class="material-icons-outlined">cancel</i>
              </button>
              {{ giftCode.Code }}
            </div>
            <div class="col-3 font-size-14 text-end">
              <span *ngIf="giftCode.EntitlementValueType == 0"
                >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
              >
              <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
              <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
            </div>
          </ng-container>
        </div>
        <hr class="my-1 bg-muted" />
        <div class="row fw-bold font-size-14 mt-2">
          <div class="col-8">{{ 'kids.membership.upgrade.payment.details.total' | translate }}</div>
          <div class="col-4 text-end">
            {{ totalBasketAmount / 100 | FortressCurrencyPipe }}
          </div>
        </div>
      </div>
      <fgbcl-errors></fgbcl-errors>

      <!--Pay now button-->
      <button class="btn btn-success fw-bold w-100 font-size-14 text-white" (click)="showModalPayment(paymentModal)">
        {{ 'child.upgrade.checkout.payment.button' | translate : { fgbdefault: 'Proceed to Payment' } }}
      </button>
      <a
        class="btn fw-bold w-100 font-size-14 text-danger justify-content-center d-lg-none d-flex"
        [routerLink]="'/child/upgrade'"
      >
        {{ 'child.upgrade.checkout.cancel' | translate : { fgbdefault: 'Proceed to Payment' } }}
      </a>
    </div>
    <!-- Shipping Form -->
    <ng-container *ngIf="editShippingAddress">
      <div class="shipping-address-form rounded card">
        <div class="form-header">
          <h5 class="form-header-title">
            {{ 'child.upgrade.checkout.shipping.form.title' | translate : { fgbdefault: 'Shipping Address' } }}
          </h5>
          <a class="clear-btn text-danger fw-bold" (click)="shippingAddressForm.reset()">{{
            'child.upgrade.checkout.shipping.form.clear.button' | translate : { fgbdefault: 'Clear Form' }
          }}</a>
        </div>
        <fgb-address-form [formGroup]="shippingAddressForm"></fgb-address-form>

        <div class="d-flex justify-content-between action-wrapper">
          <button class="btn btn-danger cancel-btn" (click)="cancelShippingForm()">
            {{ 'child.upgrade.checkout.form.cancel.button' | translate : { fgbdefault: 'Cancel' } }}
          </button>
          <button class="btn btn-success save-btn" (click)="updateShippingAddress()">
            {{ 'child.upgrade.checkout.form.save.button' | translate : { fgbdefault: 'Save' } }}
          </button>
        </div>
      </div>
    </ng-container>
    <!-- Billing Form -->
    <ng-container *ngIf="editBillingAddress">
      <div class="billing-address-form rounded card">
        <div class="form-header">
          <h5 class="form-header-title">
            {{ 'child.upgrade.checkout.billing.form.title' | translate : { fgbdefault: 'Billing Address' } }}
          </h5>
          <a class="clear-btn text-danger fw-bold" (click)="billingAddressForm.reset()">{{
            'child.upgrade.checkout.form.clear.button' | translate : { fgbdefault: 'Clear Form' }
          }}</a>
        </div>
        <fgb-address-form [formGroup]="billingAddressForm"></fgb-address-form>
        <div class="d-flex justify-content-between action-wrapper">
          <button class="btn btn-danger cancel-btn" (click)="cancelBillingForm()">
            {{ 'child.upgrade.checkout.form.cancel.button' | translate : { fgbdefault: 'Cancel' } }}
          </button>
          <button class="btn btn-success save-btn" (click)="updateBillingAddress()">
            {{ 'child.upgrade.checkout.form.save.button' | translate : { fgbdefault: 'Save' } }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Address Card -->
  <ng-template #addressTemplate let-address let-title="title" let-type="type">
    <div class="card address-card">
      <div class="address-card-header">
        <h5 class="payment-address-title">{{ title }}</h5>

        <ng-container *ngIf="type === 'billing'">
          <a class="edit-btn text-primary" (click)="editBillingAddress = true">{{
            'registration.payment.edit.button' | translate
          }}</a>
        </ng-container>

        <ng-container *ngIf="type === 'shipping'">
          <a class="edit-btn text-primary" (click)="editShippingAddress = true">{{
            'registration.payment.edit.button' | translate
          }}</a>
        </ng-container>
      </div>
      <div class="registration-payment-address">
        <div class="address-container font-size-14">
          <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Street }"></ng-container>
          <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Town }"></ng-container>
          <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.County }"></ng-container>
          <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Postcode }"></ng-container>
          <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Country, lastItem: true }"></ng-container>
        </div>
      </div>
    </div>

    <!-- Template to display comma if address is not null -->
    <ng-template #addressName let-item let-lastItem="lastItem">
      <ng-container *ngIf="item">{{ item }}<ng-container *ngIf="!lastItem">, </ng-container></ng-container>
    </ng-template>
  </ng-template>
</div>
}@else {
<div class="loading-container">
  <fgbcl-loading></fgbcl-loading>
</div>
}

<ng-template #paymentModal let-modal>
  <div class="registration-payment-modal">
    <div class="registration-payment-add-payment-modal-header">
      <h5 class="registration-payment-modal-title">
        {{ 'fgbcl.registration.payment.modal.title' | translate : { fgbdefault: 'Add Payment Details' } }}
      </h5>
      <button class="close-btn" (click)="modal.close()"><i class="material-icons">close</i></button>
    </div>
    <div class="payment-container">
      <div class="payment-form mt-3">
        <!-- Payment Provider Form -->
        <div *ngIf="loadingPaymentForm">
          <div class="mt-3">
            <fgbcl-loading></fgbcl-loading>
          </div>
        </div>
        <!-- Payment Provider Form -->
        <div id="paymentdiv"></div>
      </div>
    </div>
    <div class="action-wrapper">
      <button class="btn text-danger cancel-btn" (click)="modal.close()">
        {{ 'registration.payment.modal.cancel.button' | translate : { fgbdefault: 'Cancel' } }}
      </button>
    </div>
  </div>
</ng-template>
